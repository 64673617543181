import React, { ReactElement } from 'react';
import { graphql, StaticQuery } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';

import '../utils/normalize.css';
import '../utils/css/screen.css';

// FIXME: Add typing for Gatsby GrapthQL queries
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const LegalWarning: React.FC<any> = (): ReactElement => (
  <StaticQuery
    query={indexQuery}
    render={data => {
      const siteTitle = data.site.siteMetadata.title;
      return (
        <Layout title={siteTitle}>
          <SEO title="Aviso Legal" keywords={[`aviso legal`, `terminos`, `condiciones`]} />
          <div className="section-page-tags section-page-general wrap">
            <h2 id="this-is-a-section">Aviso legal y términos de uso</h2>
            <p>
              En este espacio, el USUARIO, podrá encontrar toda la información relativa a los términos y condiciones
              legales que definen las relaciones entre los usuarios y nosotros como responsables de esta web. Como
              usuario, es importante que conozcas estos términos antes de continuar tu navegación. SiChef!.Como
              responsable de esta web, asume el compromiso de procesar la información de nuestros usuarios y clientes
              con plenas garantías y cumplir con los requisitos nacionales y europeos que regulan la recopilación y uso
              de los datos personales de nuestros usuarios. Esta web, por tanto, cumple rigurosamente con el RGPD
              (REGLAMENTO (UE) 2016/679 de protección de datos) y la LSSI-CE la Ley 34/2002, de 11 de julio, de
              servicios de la sociedad de la información y de comercio electrónico.
            </p>
            <h2 id="this-is-a-section">Condiciones de uso</h2>
            <p>
              Las presentes Condiciones Generales regulan el uso (incluyendo el mero acceso) de las páginas de la web,
              integrantes del sitio web de sichef.app incluidos los contenidos y servicios puestos a disposición en
              ellas. Toda persona que acceda a la web, sichef.app (“Usuario”) acepta someterse a las Condiciones
              Generales vigentes en cada momento del portal sichef.app.
            </p>
            <h2 id="this-is-a-section">Datos personales que recopilamos y como lo hacemos</h2>
            Leer <a href="">Política de Privacidad</a>
            <h2 id="this-is-a-section">Compromisos y obligaciones de los usuarios</h2>
            <p>
              El Usuario queda informado, y acepta, que el acceso a la presente web no supone, en modo alguno, el inicio
              de una relación comercial con sichef.app. De esta forma, el usuario se compromete a utilizar el sitio Web,
              sus servicios y contenidos sin contravenir la legislación vigente, la buena fe y el orden público. Queda
              prohibido el uso de la web, con fines ilícitos o lesivos, o que, de cualquier forma, puedan causar
              perjuicio o impedir el normal funcionamiento del sitio web. Respecto de los contenidos de esta web, se
              prohíbe:Su reproducción, distribución o modificación, total o parcial, a menos que se cuente con la
              autorización de sus legítimos titulares;Cualquier vulneración de los derechos del prestador o de los
              legítimos titulares;Su utilización para fines comerciales o publicitarios. En la utilización de la web,
              sichef.app, el Usuario se compromete a no llevar a cabo ninguna conducta que pudiera dañar la imagen, los
              intereses y los derechos de sichef.app o de terceros o que pudiera dañar, inutilizar o sobrecargar el
              portal (indicar dominio) o que impidiera, de cualquier forma, la normal utilización de la web. No
              obstante, el Usuario debe ser consciente de que las medidas de seguridad de los sistemas informáticos en
              Internet no son enteramente fiables y que, por tanto sichef.app no puede garantizar la inexistencia de
              virus u otros elementos que puedan producir alteraciones en los sistemas informáticos (software y
              hardware) del Usuario o en sus documentos electrónicos y ficheros contenidos en los mismos.
            </p>
            <h2 id="this-is-a-section">Medidas de seguridad</h2>
            <p>
              Los datos personales comunicados por el usuario a sichef.app pueden ser almacenados en bases de datos
              automatizadas o no, cuya titularidad corresponde en exclusiva a sichef.app, asumiendo ésta todas las
              medidas de índole técnica, organizativa y de seguridad que garantizan la confidencialidad, integridad y
              calidad de la información contenida en las mismas de acuerdo con lo establecido en la normativa vigente en
              protección de datos. La comunicación entre los usuarios y sichef.app utiliza un canal seguro, y los datos
              transmitidos son cifrados gracias a protocolos a https, por tanto, garantizamos las mejores condiciones de
              seguridad para que la confidencialidad de los usuarios esté garantizada.
            </p>
            <h2 id="this-is-a-section">Derechos de propiedad intelectual e industrial</h2>
            <p>
              En virtud de lo dispuesto en los artículos 8 y 32.1, párrafo segundo, de la Ley de Propiedad Intelectual,
              quedan expresamente prohibidas la reproducción, la distribución y la comunicación pública, incluida su
              modalidad de puesta a disposición, de la totalidad o parte de los contenidos de esta página web, con fines
              comerciales, en cualquier soporte y por cualquier medio técnico, sin la autorización de sichef.app. El
              usuario se compromete a respetar los derechos de Propiedad Intelectual e Industrial titularidad de
              sichef.app. El usuario conoce y acepta que la totalidad del sitio web, conteniendo sin carácter exhaustivo
              el texto, software, contenidos (incluyendo estructura, selección, ordenación y presentación de los mismos)
              podcast, fotografías, material audiovisual y gráficos, está protegida por marcas, derechos de autor y
              otros derechos legítimos, de acuerdo con los tratados internacionales en los que España es parte y otros
              derechos de propiedad y leyes de España. En el caso de que un usuario o un tercero consideren que se ha
              producido una violación de sus legítimos derechos de propiedad intelectual por la introducción de un
              determinado contenido en la web, deberá notificar dicha circunstancia a sichef.app indicando: Datos
              personales del interesado titular de los derechos presuntamente infringidos, o indicar la representación
              con la que actúa en caso de que la reclamación la presente un tercero distinto del interesado. Señalar los
              contenidos protegidos por los derechos de propiedad intelectual y su ubicación en la web, la acreditación
              de los derechos de propiedad intelectual señalados y declaración expresa en la que el interesado se
              responsabiliza de la veracidad de las informaciones facilitadas en la notificación
            </p>
            <h2 id="this-is-a-section">Enlaces externos</h2>
            <p>
              Las páginas de la web sichef.app, podría proporcionar enlaces a otros sitios web propios y contenidos que
              son propiedad de terceros. El único objeto de los enlaces es proporcionar al Usuario la posibilidad de
              acceder a dichos enlaces. sichef.app no se responsabiliza en ningún caso de los resultados que puedan
              derivarse al Usuario por acceso a dichos enlaces. Asimismo, el usuario encontrará dentro de este sitio,
              páginas, promociones, programas de afiliados que acceden a los hábitos de navegación de los usuarios para
              establecer perfiles. Esta información siempre es anónima y no se identifica al usuario. La Información que
              se proporcione en estos Sitios patrocinado o enlaces de afiliados está sujeta a las políticas de
              privacidad que se utilicen en dichos Sitios y no estará sujeta a esta política de privacidad. Por lo que
              recomendamos ampliamente a los Usuarios a revisar detalladamente las políticas de privacidad de los
              enlaces de afiliado. El Usuario que se proponga establecer cualquier dispositivo técnico de enlace desde
              su sitio web al portal sichef.app deberá obtener la autorización previa y escrita de sichef.app El
              establecimiento del enlace no implica en ningún caso la existencia de relaciones entre sichef.app y el
              propietario del sitio en el que se establezca el enlace, ni la aceptación o aprobación por parte de
              sichef.app de sus contenidos o servicios
            </p>
            <h2 id="this-is-a-section">Exlusión de garantías y responsabilidad</h2>
            <p>
              El Prestador no otorga ninguna garantía ni se hace responsable, en ningún caso, de los daños y perjuicios
              de cualquier naturaleza que pudieran traer causa de: La falta de disponibilidad, mantenimiento y efectivo
              funcionamiento de la web, o de sus servicios y contenidos; La existencia de virus, programas maliciosos o
              lesivos en los contenidos; El uso ilícito, negligente, fraudulento o contrario a este Aviso Legal; La
              falta de licitud, calidad, fiabilidad, utilidad y disponibilidad de los servicios prestados por terceros y
              puestos a disposición de los usuarios en el sitio web. El prestador no se hace responsable bajo ningún
              concepto de los daños que pudieran dimanar del uso ilegal o indebido de la presente página web.
            </p>
          </div>
        </Layout>
      );
    }}
  />
);

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    benchAccounting: file(relativePath: { eq: "bench-accounting-49909-unsplash.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default LegalWarning;
